@import '../../../../scss/theme-bootstrap';

.sticky-footer-chat {
  display: block;
  #{$ldirection}: auto;
  #{$rdirection}: 0;
  position: fixed;
  z-index: 999;
  bottom: 0;
  @include breakpoint($medium-up) {
    #{$rdirection}: 25px;
  }
  img {
    height: 80px;
    #{$ldirection}: 0;
    position: relative;
    width: auto;
  }
  .LPMcontainer {
    margin: 0 !important;
  }
  body.samples &,
  body.opc__shipping &,
  body.opc__payment &,
  body.opc__review & {
    display: none;
  }
  body.site-header-menu--opened &,
  body.search-active & {
    display: none;
    @include breakpoint($large-up) {
      display: block;
    }
  }
}
